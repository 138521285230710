.scrollcrafting-container {
	border: 1px solid gray;
	background-color: rgba(0, 0, 0, 0.5);
	display: grid;
	grid-template-columns: 60px 2fr 5fr 150px;
	grid-template-rows: 70px 8px auto;
	grid-template-areas: 'image title resources button' 'bar bar bar bar' 'totals totals totals totals';
	position: relative;
	color: white;
	width: 100%;
	margin-bottom: 10px;
}

.scrollcrafting-image {
	grid-area: image;
	height: 60px;
	width: 60px;
	vertical-align: middle;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	margin-top: auto;
	margin-bottom: auto;
}

.scrollcrafting-title {
	grid-area: title;
	text-align: center;
	vertical-align: middle;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 10px;
	font-size: 18px;
	position: relative;
}

.scrollcrafting-resources {
	grid-area: resources;
	max-height: 100%;
	padding-right: 10px;
}

.scrollcrafting-resources > div {
	display: flex;
	flex-direction: row-reverse;
}

.scrollcrafting-property {
	min-height: 25px;
	padding: 3px;
	border: 1px solid gray;
	border-radius: 10px;
	margin-top: 5px;
	margin-bottom: 0;
	margin-right: 5px;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

.scrollcrafting-property img {
	margin-right: 4px;
}

.scrollcrafting-button {
	grid-area: button;
	width: 100%;
	margin: 0;
	text-align: center !important;
}

.scrollcrafting-button button {
	width: 100%;
	height: 100%;
	border-radius: 0;
	box-shadow: none;
	background: linear-gradient(180deg, #485563, #29323c);
	border: 2px solid hsla(0, 0%, 75.3%, 0.2);
	cursor: pointer;
}

.scrollcrafting-button button:hover {
	background: linear-gradient(180deg, #0f2027, #203a43, #2c5364) !important;
}

.scrollcrafting-button button:disabled,
.scrollcrafting-button button[disabled] {
	background-color: #dfdfdf !important;
	box-shadow: none;
	color: #9f9f9f !important;
	cursor: default;
}

.scrollcrafting-locked-cover {
	grid-column-start: image-start; /* only takes up one cell because grid-column-end default value is one */
	grid-column-end: span 4;
	grid-row-start: image-start;
	grid-row-end: span 2;
	align-self: stretch;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 1;
}

.scrollcrafting-locked-cover p {
	position: relative;
	text-align: center;
	font-size: 32px;
	margin: 0;
}

.scrollcrafting-locked-cover p img {
	vertical-align: middle;
	margin-right: 5px;
}

.scrollcrafting-locked-cover p:first-child {
	right: 0;
	font-size: 40px;
	margin-top: 4px;
}

.icon50 {
	height: 50px;
	width: 50px;
}

.scrollcrafting-progress-container {
	grid-area: bar;
	border-radius: 0;
	overflow: hidden;
	background-color: transparent !important;
	height: 100% !important;
	width: 100%;
}

.scrollcrafting-progress-bar {
	will-change: transform;
	background-color: rgba(135, 186, 252, 0.9) !important;
	border-radius: 20px !important;
	transition-duration: 0.1s !important;
	transition-timing-function: linear !important;
	box-shadow: inset 0 0 2px 1px rgba(255, 255, 255, 0.356);
}

#scrollcrafting-tooltip {
	position: absolute;
	right: 4px;
	top: 4px;
}

.scrollcrafting-totals-bar {
	grid-area: totals;
	padding: 3px 5px;
	background-color: rgba(90, 90, 90, 0.74);
	display: flex;
	justify-content: center;
	color: #ddd;
}

.scrollcrafting-totals-bar .key-data {
	margin: 0 5px;
	color: white;
	font-weight: bold;
}

.action-queue .scrollcrafting-button button {
	height: 50%;
}

.action-queue .scrollcrafting-button .add-queue-button {
	background: linear-gradient(180deg, #485563, #29323c);
}
